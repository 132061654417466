import React from 'react'
import Layout from '../layouts'
import { Section, SEO } from '../components'

export default function PrivacyPage() {
  return (
    <Layout>
      <SEO title="Privacy Policy" description="Pure Func Privacy Policy" pathname="/privacy" />
      <Section>
        <>
          <h1>Privacy Policy</h1>
        </>
      </Section>
    </Layout>
  )
}
